


























import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import { useAuth } from "@/api_connectors/auth";
import { useToast } from "@/composables/useToast";
import VerifyEmailToast from "@/components/Toast/VerifyEmailToast.vue";
import RequestSentToast from "@/components/Toast/RequestSentToast.vue";

export default defineComponent({
    name: "ShowConfirm",
    components: { RequestSentToast, VerifyEmailToast },
    props: {
        hollow: Boolean,
        welcome: Boolean
    },
    setup(props, context) {
        const { isEmailVerified, resendVerification } = useAuth()
        const { showToast } = useToast()

        const checkVerify = async (): Promise<any> => {
            const response = await isEmailVerified()
            // console.log(response)

            if (!response?.data.emailVerified) return showToast('verify-email-toast')

            if (props.welcome) return context.emit('next')

            if (router.currentRoute.query.next === 'RedeemCode') return await router.push({
                name: 'RedeemCode',
                params: { voucherCode: router.currentRoute.query.code as string }
            })

            return await router.push('/license')
        }

        return {
            checkVerify,
            resendVerification,
            showToast,
        }
    }
})
