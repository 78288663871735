<template>
    <b-toast toaster="b-toaster-top-center" id="verify-email-toast" no-close-button variant="warning">
        <template slot="default">
            <div>{{ $t('button.verifyEmail') }}</div>
        </template>
    </b-toast>
</template>

<script>
export default {
    name: "VerifyEmailToast"
}
</script>

<style scoped>

</style>