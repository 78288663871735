

























import { defineComponent, onMounted, ref } from "@vue/composition-api";
import VerifyEmailToast from "@/components/Toast/VerifyEmailToast.vue";
import ShowConfirm from "@/components/ShowConfirm.vue";
import RequestReset from "@/views/RequestReset.vue";
import LoginInterface from "@/components/LoginInterface.vue";
import { TOKEN_KEY } from "@/composables/useApi";
import router from "@/router";
import { Route } from 'vue-router';

export default defineComponent({
    name: "WelcomeSignupTMF",
    components: {
        RequestReset,
        ShowConfirm,
        VerifyEmailToast,
        LoginInterface
    },
    setup() {
        const showConfirm = ref(false)
        const mode = ref('signup')

        onMounted(async () => {
            if (await localStorage.getItem(TOKEN_KEY)) return pushVoucherRoute()
        })

        const pushVoucherRoute = async (): Promise<Route> => {
            console.log('pushVoucherRoute')
            return router.push('/welcomeVoucher-tmf')
        }

        return {
            showConfirm,
            mode,
            pushVoucherRoute
        }
    }
})
